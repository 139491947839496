import React from "react";
import { Layout } from "antd";
import { HtmlMeta } from "$/components/HtmlMeta";
import { useRoleRedirect, useScrollTo } from "$/hooks";
import { UserRole } from "$/graphql/types.generated";
import { IPageBaseProps } from "$/interfaces";
import { AdminStats } from "$/pages/Home/AdminDashboard/components/AdminStats";

interface IProps extends IPageBaseProps {}

export const CompanyData: React.FC<IProps> = () => {
  useScrollTo();
  useRoleRedirect([UserRole.Admin, UserRole.Sponsor, UserRole.Individual, UserRole.Superadmin]);

  return (
    <Layout>
      <HtmlMeta title="Company Data" />
      <Layout.Content className="site-layout-content">
        <div className="max-w-[1240px] mx-auto">
          <AdminStats />
        </div>
      </Layout.Content>
    </Layout>
  );
};
