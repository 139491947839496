import { Col, Row } from "antd";
import { theme } from "$/configs/theme";
import { ROUTE_PATHS } from "$/configs/routes";
import { ActionTile } from "$/components/ActionTile";
import { useUser } from "$/state/user";
import HeadlineStats from "./components/HeadlineStats";
import { OrderByArg, SessionState, useListSessionsQuery } from "$/graphql/types.generated";
import { addMinutes, startOfHour } from "date-fns";
import { useCompanySubscription } from "$/state/company-subscription";
import { PackageDashboard } from "../PackageDashboard";

export const AdminDashboard = () => {
  const user = useUser();
  const companySubscription = useCompanySubscription();

  const packageUser =
    user.currentUser.activePackage ||
    (companySubscription.state.packageCustomer && !companySubscription.state.subscriptionCustomer);

  const SPONSOR_SUB_NAVIGATION_ITEMS = [
    {
      id: "id1",
      navTitle: "More Happi 101",
      title: "Tell your team about More Happi",
      to: "https://midnight-revolve-64c.notion.site/1-Tell-your-team-about-More-Happi-baefc4a0fcc24c69830fbf214ecf0a08",
      color: theme.colors.blue,
    },
    {
      id: "id2",
      navTitle: "More Happi 101",
      title: 'Watch our "How to use Coaching" playlist (10 mins)',
      to: ROUTE_PATHS.ONBOARDING_VIDEOS,
      color: theme.colors.blue,
    },
    {
      id: "id3",
      navTitle: "My Team",
      title: "Manage my team or see my team's data",
      to: ROUTE_PATHS.MY_ACCOUNT,
      color: theme.colors.yellow,
    },
    {
      id: "id4",
      navTitle: "Book a Session",
      title: "Ready to book? Meet our coaches.",
      to: ROUTE_PATHS.SESSIONS.BOOK_COACHING.BROWSE,
      cta: true,
      color: theme.colors.lightGrey,
    },
  ];

  const { data: allCompanySessions } = useListSessionsQuery({
    where: {
      teamMember: { company: { id: { equals: user.currentUser.teamMember.companyId } } },
    },
    orderBy: [{ startsAt: OrderByArg.Asc }],
  });

  const { data: pastCompanySessions } = useListSessionsQuery({
    where: {
      teamMember: { company: { id: { equals: user.currentUser.teamMember.companyId } } },
      state: { in: [SessionState.Confirmed, SessionState.ConfirmedCoach, SessionState.Completed] },
      startsAt: { lte: addMinutes(startOfHour(new Date()), 30) },
    },
    orderBy: [{ startsAt: OrderByArg.Asc }],
  });

  const { data: futureCompanySessions } = useListSessionsQuery({
    where: {
      teamMember: { company: { id: { equals: user.currentUser.teamMember.companyId } } },
      state: { in: [SessionState.Confirmed, SessionState.ConfirmedCoach, SessionState.Completed] },
      startsAt: { gte: addMinutes(startOfHour(new Date()), 30) },
    },
    orderBy: [{ startsAt: OrderByArg.Asc }],
  });

  const futureUsersCount = new Set(
    futureCompanySessions?.listSessions?.map((session) => session?.teamMember?.id),
  ).size;

  const uniqueUsersCount = new Set(
    allCompanySessions?.listSessions
      ?.filter((session) => session.state !== SessionState.Cancelled || SessionState.CancelledCoach)
      .map((session) => session?.teamMember?.id),
  ).size;

  const allSessionsAmount = allCompanySessions?.listSessions.length || 0;
  const pastSessionsAmount = pastCompanySessions?.listSessions.length || 0;
  const futureSessionsAmount = futureCompanySessions?.listSessions.length || 0;

  const headlineData = [
    { title: "Bookings Made", value: allSessionsAmount },
    { title: "Sessions Completed", value: pastSessionsAmount },
    { title: "Active Users ", value: uniqueUsersCount },
    { title: "Upcoming Sessions", value: futureSessionsAmount },
    { title: "Users with Future Bookings", value: futureUsersCount },
  ];

  return (
    <div className="max-w-[1240px] mx-auto">
      <div className="w-full flex flex-col justify-between md:flex-row">
        <div className="flex flex-col p-4">
          <h3 className="text-3xl">
            Hey {user.currentUser.firstName}, welcome to your <u>new</u> Admin Dashboard!
          </h3>
          <h4 className="text-lg font-normal">
            Here you can find a quick snapshot of the key information about your team&apos;s More
            Happi usage.
          </h4>
          <h4 className="text-lg font-normal">
            To see a more detailed report, visit the <i>new</i> data page{" "}
            <a href="/company/data">here</a>.
          </h4>
          <h4 className="text-lg font-normal">
            We&apos;re continuously working to make this dashboard as useful as possible for admins.
          </h4>
          <h4 className="text-lg font-normal">
            If you have any ideas for improvements, reach out to us at{" "}
            <a href={"mailto:hey@morehappi.com"} target="_blank" rel="noreferrer">
              hey@morehappi.com
            </a>
            .
          </h4>
        </div>
        <div className="flex flex-grow items-center">
          <img src="/images/My_account.svg" alt="huddle" className="h-[220px] w-[220px] m-auto" />
        </div>
      </div>
      <HeadlineStats data={headlineData} />
      {packageUser ? (
        <PackageDashboard />
      ) : (
        <>
          <a href="/sessions" className="hover:no-underline">
            <div className="bg-white mt-4 rounded-xl p-4 cursor-pointer border border-solid border-lightPurple">
              <div className="text-4xl w-full text-center">
                View my previous and upcoming sessions
              </div>
            </div>
          </a>

          <Row className="mh-row-top-md" gutter={{ xs: 4, sm: 8, md: 16, lg: 24 }}>
            {SPONSOR_SUB_NAVIGATION_ITEMS.map((item) => (
              <Col
                key={item.id}
                className="mh-vertical-spacer"
                xs={24}
                sm={24}
                md={12}
                lg={12}
                xl={6}
              >
                <ActionTile {...item} />
              </Col>
            ))}
          </Row>
        </>
      )}
    </div>
  );
};
