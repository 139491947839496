import { ListSessionsQuery, SessionState } from "$/graphql/types.generated";
import dayjs from "dayjs";
import { useState } from "react";
import ExportCSV from "./ExportCSV";
import { useIsMobile } from "$/hooks";

interface SessionData {
  month: string;
  bookingsMade: number;
  employeesBooking: string[];
  sessionsCompleted: number;
  sessionsCancelled: number;
}

type GroupedData = Record<string, SessionData>;

export default function SessionStats({
  sessionsList,
}: {
  sessionsList: ListSessionsQuery["listSessions"];
}) {
  const isMobile = useIsMobile(500);
  const [showTable, setShowTable] = useState(true);

  const groupedData = sessionsList
    .filter((session) => session.state !== SessionState.Cancelled || SessionState.CancelledCoach)
    .reduce<GroupedData>((acc, current) => {
      const month = dayjs(current.startsAt).format("MMMM YY");
      if (!acc[month]) {
        acc[month] = {
          month,
          bookingsMade: 0,
          sessionsCompleted: 0,
          sessionsCancelled: 0,
          employeesBooking: [],
        };
      }
      if (dayjs(current.createdAt).format("MMMM YY") === month) {
        acc[month].bookingsMade += 1;
      }
      if (
        dayjs(current.startsAt) < dayjs() &&
        dayjs(current.startsAt).format("MMMM YY") === month &&
        current.state === SessionState.Confirmed
      ) {
        acc[month].sessionsCompleted += 1;
      }
      if (
        dayjs(current.startsAt).format("MMMM YY") === month &&
        current.state === SessionState.Cancelled
      ) {
        acc[month].sessionsCancelled += 1;
      }
      if (
        dayjs(current.createdAt) < dayjs() &&
        dayjs(current.startsAt).format("MMMM YY") === month &&
        !acc[month].employeesBooking.includes(current.teamMember.id)
      ) {
        acc[month].employeesBooking.push(current.teamMember.id);
      }
      return acc;
    }, {});

  const sessionsByMonth = Object.values(groupedData).reverse();

  const exportData = sessionsByMonth.map((user: SessionData) => ({
    Month: user.month,
    BookingsMade: user.bookingsMade,
    EmployeesBooking: user.employeesBooking.length,
    SessionsCompleted: user.sessionsCompleted,
    SessionsCancelled: user.sessionsCancelled,
  }));

  return (
    <>
      <div className="w-full flex flex-col justify-around mt-6 pb-6">
        <div className="w-full flex items-center justify-between">
          {!isMobile && (
            <div className="flex-grow ml-auto">
              <div className="max-w-20 cursor-pointer" onClick={() => setShowTable(!showTable)}>
                {showTable ? "Hide" : "Show"} fields
              </div>
            </div>
          )}
          <h3 className="flex justify-center flex-grow font-semibold text-2xl">
            Sessions by month
          </h3>
          <div className="flex flex-grow items-end justify-end">
            <ExportCSV data={exportData} filename="More-Happi-Session-Data.csv" />
          </div>
        </div>
        {isMobile ? (
          <div className="w-full text-center">
            <p>This table is not shown on mobile but can be exported as normal</p>
          </div>
        ) : (
          <div className="max-h-[560px] overflow-auto">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50 sticky top-0 z-10">
                <tr>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Month
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Bookings Made
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Sessions Completed
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Employees Booking
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Sessions Cancelled
                  </th>
                </tr>
              </thead>
              {showTable && (
                <tbody className="bg-white divide-y divide-gray-200">
                  {exportData.map((month) => (
                    <tr key={month.Month}>
                      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                        {month.Month}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        {month.BookingsMade}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        {month.SessionsCompleted}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        {month.EmployeesBooking}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        {month.SessionsCancelled}
                      </td>
                    </tr>
                  ))}
                </tbody>
              )}
            </table>
          </div>
        )}
      </div>
    </>
  );
}
