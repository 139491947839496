import { useUser } from "$/state/user";
import {
  ActivationState,
  OrderByArg,
  SessionState,
  useListCompanySessionReviewsQuery,
  useListSessionsQuery,
  useListTeamMembersQuery,
  useListUserPackagesQuery,
} from "$/graphql/types.generated";
import { addMinutes, startOfHour, subMonths } from "date-fns";
import { getReviews } from "$/components/CoachCard/CoachTestimonials";
import { useCompany } from "$/state/company";
import HeadlineStats from "./HeadlineStats";
import ProgrammeStats from "./ProgrammeStats";
import SessionStats from "./SessionStats";
import UsersStats from "./UsersStats";
import UsersByMonthStats from "./UsersByMonthStats";
import { useState } from "react";
import { SearchTimeSelect } from "$/components/Select/Selectors/SearchTime";
import TestimonialStats from "./TestimonialStats";
import GraphStats from "./GraphStats";
import { LoadingSpinner } from "$/components/LoadingSpinner";

export const AdminStats = () => {
  const user = useUser();
  const company = useCompany();
  const [searchMonths, setSearchMonths] = useState<number | null>(null);

  const { data: teamMembers, refetch } = useListTeamMembersQuery({
    where: {
      companyId: {
        equals: company?.currentCompany?.id,
      },
      user: {
        id: { not: undefined },
        activationState: { not: { equals: ActivationState.Disabled } },
      },
    },
    activeWhere: {
      companyId: {
        equals: company?.currentCompany?.id,
      },
      user: { id: { not: undefined }, activationState: { equals: ActivationState.Activated } },
    },

    orderBy: [
      { user: { activationState: OrderByArg.Asc } },
      {
        createdAt: OrderByArg.Asc,
      },
    ],
  });

  const invitedUsers = teamMembers?.listTeamMembers.filter(
    (teamMember) => teamMember.user.activationState === ActivationState.Invited,
  );

  const { data: allCompanySessions } = useListSessionsQuery({
    where: {
      teamMember: { company: { id: { equals: user.currentUser.teamMember.companyId } } },
      ...(searchMonths !== null && {
        startsAt: {
          gte: subMonths(startOfHour(new Date()), searchMonths),
          lte: addMinutes(startOfHour(new Date()), 30),
        },
      }),
    },
    orderBy: [{ startsAt: OrderByArg.Asc }],
  });

  const { data: pastCompanySessions } = useListSessionsQuery({
    where: {
      teamMember: { company: { id: { equals: user.currentUser.teamMember.companyId } } },
      state: { in: [SessionState.Confirmed, SessionState.ConfirmedCoach, SessionState.Completed] },
      ...(searchMonths !== null
        ? {
            startsAt: {
              gte: subMonths(startOfHour(new Date()), searchMonths),
              lte: addMinutes(startOfHour(new Date()), 30),
            },
          }
        : {
            startsAt: { lte: addMinutes(startOfHour(new Date()), 30) },
          }),
    },
    orderBy: [{ startsAt: OrderByArg.Asc }],
  });

  const { data: futureCompanySessions } = useListSessionsQuery({
    where: {
      teamMember: { company: { id: { equals: user.currentUser.teamMember.companyId } } },
      state: { in: [SessionState.Confirmed, SessionState.ConfirmedCoach, SessionState.Completed] },
      startsAt: { gte: addMinutes(startOfHour(new Date()), 30) },
    },
    orderBy: [{ startsAt: OrderByArg.Asc }],
  });

  const allSessionsAmount = allCompanySessions?.listSessions.length;
  const pastSessionsAmount = pastCompanySessions?.listSessions.length;
  const futureSessionsAmount = futureCompanySessions?.listSessions.length;

  const uniqueUsersCount = new Set(
    allCompanySessions?.listSessions
      ?.filter((session) => session.state !== SessionState.Cancelled || SessionState.CancelledCoach)
      .map((session) => session?.teamMember?.id),
  ).size;

  const futureUsersCount = new Set(
    futureCompanySessions?.listSessions?.map((session) => session?.teamMember?.id),
  ).size;

  const { data: companySessionReviews } = useListCompanySessionReviewsQuery({
    companyId: user?.currentUser?.teamMember?.companyId,
  });

  const { data: allProgrammes } = useListUserPackagesQuery({
    where: {
      user: {
        teamMember: { company: { id: { equals: user?.currentUser?.teamMember?.companyId } } },
      },
      ...(searchMonths !== null && {
        createdAt: {
          gte: subMonths(startOfHour(new Date()), searchMonths),
          lte: addMinutes(startOfHour(new Date()), 30),
        },
      }),
    },
    // this is required for some reason
    orderBy: { endDate: OrderByArg.Asc },
  });

  const headlineData = [
    { title: "Bookings made", value: allSessionsAmount },
    { title: "Sessions completed", value: pastSessionsAmount },
    { title: "Active users ", value: uniqueUsersCount },
    { title: "Upcoming sessions", value: futureSessionsAmount },
    { title: "Users with future bookings", value: futureUsersCount },
  ];

  const { meanReview, meanNps, totalCount, byDate, byStars } = getReviews(
    [],
    companySessionReviews?.listSessionReviews,
  );

  if (
    !headlineData.length ||
    !teamMembers?.activeCount ||
    !byDate ||
    !allProgrammes?.listUserPackages ||
    !allCompanySessions?.listSessions
  ) {
    return <LoadingSpinner />;
  }

  return (
    <>
      <SearchTimeSelect value={searchMonths} onChange={(value) => setSearchMonths(value)} />
      <HeadlineStats data={headlineData} />
      <div className="flex flex-wrap">
        <GraphStats
          totalCount={totalCount}
          meanReview={meanReview}
          meanNps={meanNps}
          byStars={byStars}
          teamMembers={teamMembers}
          invitedUsers={invitedUsers}
          refetch={refetch}
          programmesList={allProgrammes?.listUserPackages}
        />
      </div>
      <TestimonialStats testimonials={byDate} />
      <ProgrammeStats programmesList={allProgrammes.listUserPackages} />
      <SessionStats sessionsList={allCompanySessions?.listSessions} />
      <UsersStats sessionsList={allCompanySessions?.listSessions} />
      <UsersByMonthStats sessionsList={allCompanySessions?.listSessions} />
    </>
  );
};
