import { useUser } from "$/state/user";
import { findUserType } from "$/utils/helpers/user";
import { useState } from "react";
import { useGetCompanyAdminUsersQuery } from "$/graphql/types.generated";
import { Modal } from "$/components/Modal";
import { Button } from "$/components/Button";
import { Info } from "lucide-react";

export const UserRoleSettings = () => {
  const user = useUser();
  const userType = findUserType(user);
  const [requestModalOpen, setRequestModalOpen] = useState<boolean>(false);

  const adminMembers = useGetCompanyAdminUsersQuery({
    where: user?.currentUser?.teamMember?.companyId,
  });

  return (
    <>
      <div className="flex">
        <div className="flex cursor-pointer text-4xl font-semibold">
          <p className="m-0">
            {userType === "Individual"
              ? "Individual account"
              : `${userType} at ${user?.currentUser?.teamMember?.company?.name}`}
          </p>
        </div>
        <div className="pl-2 pt-3">
          <Info
            size={"20px"}
            className="cursor-pointer"
            onClick={() => setRequestModalOpen(true)}
          />
        </div>
      </div>
      {requestModalOpen && userType !== "Individual" && (
        <Modal onClose={() => setRequestModalOpen(false)}>
          <div>
            <h2 className="text-2xl font-semibold text-center m-0">Your role is:</h2>
            <h2 className="text-2xl font-semibold text-center mb-4">
              {userType} at {user?.currentUser?.teamMember?.company?.name}
            </h2>
            <h3 className="text-center text-xl mb-4">Does this look right to you? </h3>
            <p className="text-center text-lg">
              This role determines what you see on your dashboard, as well as determining access to
              admin priveleges and role specific content.
            </p>
            {userType === "Admin" ? (
              <>
                <p className="text-center text-lg">
                  As you are an Admin at {user?.currentUser?.teamMember?.company?.name}, you can see
                  your team&apos;s data and manage your team on More Happi, including editing
                  users&apos; roles.
                </p>
                <a href="/my-account" className="w-full">
                  <Button primary className="w-full">
                    My team account
                  </Button>
                </a>
              </>
            ) : (
              <>
                <p className="text-center text-lg">
                  You can request for an admin at {user?.currentUser?.teamMember?.company?.name} to
                  change your role if you think it is incorrect.
                </p>
                <p className="text-center text-lg">
                  The admin users at {user?.currentUser?.teamMember?.company?.name} are:
                </p>
                <ul className="flex flex-col justify-center">
                  {adminMembers.data.getCompanyAdminUsers.map((adminMember) => {
                    return (
                      <li key={adminMember.id}>
                        {adminMember.user.firstName} {adminMember.user.lastName} -{" "}
                        {adminMember.user.email}
                      </li>
                    );
                  })}
                </ul>
              </>
            )}
          </div>
        </Modal>
      )}
    </>
  );
};
