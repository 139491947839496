import { ListSessionsQuery } from "$/graphql/types.generated";
import dayjs from "dayjs";
import { useState } from "react";
import ExportCSV from "./ExportCSV";
import { useIsMobile } from "$/hooks";

interface UsersByMonthData {
  teamMemberId: string;
  name: string;
  isActive: boolean;
  sessionsBooked: number;
  sessionsCancelled: number;
  noShows: number;
  sessionsCompleted: number;
  sessionsUpcoming: number;
  lastBooking: string;
}

export default function UsersByMonthStats({
  sessionsList,
}: {
  sessionsList: ListSessionsQuery["listSessions"];
}) {
  const isMobile = useIsMobile(500);
  const [showTable, setShowTable] = useState(true);

  const teamMembers = [
    ...new Set(
      sessionsList.map(
        (session) => `${session.teamMember.user.firstName} ${session.teamMember.user.lastName}`,
      ),
    ),
  ];
  const months = [
    ...new Set(sessionsList.map((session) => dayjs(session.createdAt).format("MMM YY"))),
  ];

  const tableData = teamMembers.map((user) => {
    const row = { user };
    months.forEach((month) => {
      row[month] = 0;
    });
    return row;
  });

  sessionsList.forEach((session) => {
    const month = dayjs(session.createdAt).format("MMM YY");
    const userRow = tableData.find(
      (row) =>
        row.user === `${session.teamMember.user.firstName} ${session.teamMember.user.lastName}`,
    );
    if (userRow) userRow[month] += 1;
  });

  const monthsHeadings = [...new Set(tableData.flatMap((user) => Object.keys(user)))].filter(
    (key) => key !== "user",
  );

  const sortedMonths = monthsHeadings.sort((a, b) => {
    const monthOrder = {
      Jan: 1,
      Feb: 2,
      Mar: 3,
      Apr: 4,
      May: 5,
      Jun: 6,
      Jul: 7,
      Aug: 8,
      Sep: 9,
      Oct: 10,
      Nov: 11,
      Dec: 12,
    };
    const [monthA, yearA] = a.split(" ");
    const [monthB, yearB] = b.split(" ");

    return Number(yearB) - Number(yearA) || monthOrder[monthB] - monthOrder[monthA];
  });

  const exportData = tableData.map((row) => ({
    user: row.user,
    ...Object.fromEntries(sortedMonths.map((month) => [month, row[month] ?? "-"])),
  }));

  return (
    <>
      <div className="w-full flex flex-col justify-around pt-6">
        <div className="w-full flex items-center justify-between">
          {!isMobile && (
            <div className="flex-grow ml-auto">
              <div className="max-w-20 cursor-pointer" onClick={() => setShowTable(!showTable)}>
                {showTable ? "Hide" : "Show"} fields
              </div>
            </div>
          )}
          <h3 className="flex justify-center flex-grow font-semibold text-2xl">
            Monthly bookings by user
          </h3>
          <div className="flex flex-grow items-end justify-end">
            <ExportCSV data={exportData} filename="More-Happi-User-Month-Data.csv" />
          </div>
        </div>
        {isMobile ? (
          <div className="w-full text-center">
            <p>This table is not shown on mobile but can be exported as normal</p>
          </div>
        ) : (
          <div className="max-h-[560px] overflow-auto">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50 sticky top-0 z-10">
                <tr>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    User
                  </th>
                  {sortedMonths.map((month) => (
                    <th
                      key={month}
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      {month}
                    </th>
                  ))}
                </tr>
              </thead>
              {showTable && (
                <tbody className="bg-white divide-y divide-gray-200">
                  {tableData.map(({ user, ...data }) => (
                    <tr key={`${user}-by-month`}>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{user}</td>
                      {sortedMonths.map((month) => (
                        <td
                          key={month}
                          className="px-6 py-4 whitespace-nowrap text-sm text-gray-500"
                        >
                          {data[month] !== undefined ? data[month] : "-"}
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              )}
            </table>
          </div>
        )}
      </div>
    </>
  );
}
