import dayjs from "dayjs";
import { useState } from "react";
import ExportCSV from "./ExportCSV";
import { Button } from "$/components/Button";
import { Mic2 } from "lucide-react";
import { DateTime } from "luxon";

export default function TestimonialStats({ testimonials }: { testimonials: any[] }) {
  const [showTable, setShowTable] = useState(false);

  const exportData = testimonials.map((testimonial) => ({
    Date: dayjs(testimonial.createdAt).format("MMM YY"),
    Testimonial: testimonial.body,
  }));

  if (!testimonials.length) return <></>;

  return (
    <>
      <div className="w-full">
        <div className="flex flex-col justify-center gap-4 text-center py-6 my-4">
          <h3 className="text-3xl">Recent testimonials</h3>
          <div className="flex flex-wrap justify-around gap-6">
            {testimonials.slice(0, 6)?.map((review, i) => (
              <div
                className="w-[95%] computer:w-1/4 flex flex-col h-fit bg-yellow border-solid border-2 border-midnight rounded-xl"
                key={`review-${i}`}
              >
                <p className="m-0 font-semibold">
                  {DateTime.fromISO(review?.createdAt).toFormat("MMMM yyyy")}
                </p>
                <p className="m-0 font-semibold">Anonymous</p>
                <div className="w-full flex flex-col bg-white rounded-b-xl border-solid border-0 border-t-2 border-midnight">
                  <p
                    className="m-4 overflow-hidden text-ellipsis"
                    style={{
                      display: "-webkit-box",
                      WebkitLineClamp: 10,
                      WebkitBoxOrient: "vertical",
                      lineClamp: 10,
                      whiteSpace: "normal",
                    }}
                  >
                    &quot;{review.body}&quot;
                  </p>
                </div>
              </div>
            ))}
            {testimonials.length === 0 && (
              <div className="w-[95%] computer:w-3/4 flex flex-col m-auto bg-white rounded-xl border-solid border-2 border-bottleGreen">
                <p className="mt-4 px-4 text-base md:text-xl">
                  Your team haven&apos;t left any testimonials yet.
                </p>
                <p className="mt-4 px-4 text-base md:text-xl">
                  Once your team has written their first tesimonial, your team&apos;s testimonial
                  stats will appear here.
                </p>
                <p className="mt-4 px-4 text-base md:text-xl">
                  Reviews and testimonials benefit our coaches, so make sure to encourage your team
                  to leave meaningful feedback on their sessions.
                </p>
              </div>
            )}
          </div>
          <div className="flex justify-center">
            <Button
              large
              tertiarySolid
              leftIcon={<Mic2 size={18} />}
              onClick={() => setShowTable(!showTable)}
            >
              {showTable ? "Hide" : "Show"} all testimonials
            </Button>
          </div>
        </div>
      </div>
      {showTable && (
        <div className="w-full flex flex-col justify-around pb-6">
          <div className="w-full flex items-center justify-center">
            <h3 className="flex justify-center flex-grow font-semibold text-2xl">
              All testimonials
            </h3>
            <div className="flex items-end justify-end">
              <ExportCSV data={exportData} filename="More-Happi-Testimonial-Data.csv" />
            </div>
          </div>
          <div className="max-h-[560px] overflow-auto">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50 sticky top-0 z-10">
                <tr>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Date
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Testimonial
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {exportData.map((testimonial, index) => (
                  <tr key={`testimonial-${index}`}>
                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                      {testimonial.Date}
                    </td>
                    <td className="px-6 py-4 text-sm text-gray-500">{testimonial.Testimonial}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </>
  );
}
