import { Button } from "$/components/Button";
import Papa from "papaparse";

const ExportCSV = ({ data, filename = "data.csv" }) => {
  const exportToCSV = () => {
    const csvData = Papa.unparse(data);

    const blob = new Blob([csvData], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    const url = URL.createObjectURL(blob);

    link.href = url;
    link.setAttribute("download", filename);
    link.click();

    URL.revokeObjectURL(url);
  };

  return (
    <Button primary onClick={exportToCSV} className="h-fit">
      Export to CSV
    </Button>
  );
};

export default ExportCSV;
