export default function HeadlineStats({ data }: { data: { title: string; value: number }[] }) {
  return (
    <div className="w-full flex flex-wrap justify-around">
      {data.map((item) => (
        <div
          key={item.title}
          className="flex w-full min-w-[150px] flex-col text-center justify-center p-4 bg-white rounded-xl mb-4 computer:w-auto"
        >
          <div className="text-lg font-semibold">{item.title}</div>
          <p className="text-3xl text-center m-0">{item.value}</p>
        </div>
      ))}
    </div>
  );
}
