import { ListUserPackagesQuery, SessionState } from "$/graphql/types.generated";
import dayjs from "dayjs";
import { useState } from "react";
import ExportCSV from "./ExportCSV";
import { useIsMobile } from "$/hooks";

export default function ProgrammeStats({
  programmesList,
}: {
  programmesList: ListUserPackagesQuery["listUserPackages"];
}) {
  const isMobile = useIsMobile(500);
  const [showTable, setShowTable] = useState(true);

  const orderedProgrammes = programmesList.sort(
    (a, b) => new Date(b.endDate).getTime() - new Date(a.endDate).getTime(),
  );

  const exportData = orderedProgrammes.map((programme) => ({
    user: `${programme.user.firstName} ${programme.user.lastName}`,
    status:
      dayjs(programme.endDate) > dayjs() ||
      !programme.sessions.every((session) => dayjs(session.startsAt) < dayjs())
        ? "Active"
        : "Completed",
    programmeSessions: programme.sessionCount,
    sessionsBooked: programme.sessions.filter(
      (session) => session.state !== SessionState.Cancelled || SessionState.CancelledCoach,
    ).length,
    futureBookings: programme?.sessions.filter((session) => dayjs(session.startsAt) > dayjs())
      .length,
    progress: `${((programme.sessions.length / programme.sessionCount) * 100).toFixed(0)}%`,
    endDate: dayjs(programme.endDate).format("DD/MM/YYYY"),
  }));

  return (
    <>
      <div className="w-full flex flex-col justify-around pb-6">
        <div className="w-full flex items-center justify-between">
          {!isMobile && (
            <div className="flex-grow ml-auto">
              <div className="max-w-20 cursor-pointer" onClick={() => setShowTable(!showTable)}>
                {showTable ? "Hide" : "Show"} fields
              </div>
            </div>
          )}
          <h3 className="flex justify-center flex-grow font-semibold text-2xl">
            Programmes by user
          </h3>
          <div className="flex flex-grow items-end justify-end">
            <ExportCSV data={exportData} filename="More-Happi-Programme-Data.csv" />
          </div>
        </div>
        {isMobile ? (
          <div className="w-full text-center">
            <p>This table is not shown on mobile but can be exported as normal</p>
          </div>
        ) : (
          <div className="max-h-[560px] overflow-auto">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50 sticky top-0 z-10">
                <tr>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    User
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    End Date
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Status
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Programme Sessions
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Sessions Booked
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Future Bookings
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Progress
                  </th>
                </tr>
              </thead>
              {showTable && (
                <tbody className="bg-white divide-y divide-gray-200">
                  {exportData.map((programme, index) => (
                    <tr key={`${programme.user}-${index}`}>
                      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                        {programme.user}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        {programme.endDate}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        {programme.status}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        {programme.programmeSessions}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        {programme.sessionsBooked}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        {programme.futureBookings}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        <div className="flex w-full bg-gray-200 rounded-full h-2.5">
                          <div
                            className="bg-yellow h-2.5 rounded-full"
                            style={{
                              width: programme.progress,
                            }}
                          />
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              )}
            </table>
          </div>
        )}
      </div>
    </>
  );
}
