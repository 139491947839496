import { Select, SelectProps } from "../index";

interface SearchTimeSelectProps extends SelectProps {
  value: number | null;
}

const repeatingAmounts = [
  { label: "All time", value: null },
  { label: "Last 3 months", value: 3 },
  { label: "Last 6 months", value: 6 },
  { label: "Last 12 months", value: 12 },
];

export function SearchTimeSelect({ value, onChange, ...props }: SearchTimeSelectProps) {
  return (
    <div className="flex justify-end md:pr-4">
      <h3 className="my-auto pb-4 pr-4">Choose data time frame</h3>
      <div>
        <Select
          className="min-w-40"
          value={repeatingAmounts.find((opt) => opt.value === value)}
          options={repeatingAmounts}
          onChange={(newVal) => onChange(newVal.value)}
          {...props}
        />
      </div>
    </div>
  );
}
