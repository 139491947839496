import { Star } from "lucide-react";
import { useIsMobile } from "$/hooks";
import OnboardingPieChart from "./OnboardingPieChart";
import { ConfirmPasswordResetModal } from "$/components/Modals/ConfirmPasswordResetModal";
import { ListTeamMembersQuery, ListUserPackagesQuery, TeamMember } from "$/graphql/types.generated";
import { Button } from "$/components/Button";
import { Modal } from "$/components/Modal";
import { useState } from "react";
import ProgrammesBarChart from "./ProgrammesBarChart";
import { Ecstatic } from "$/components/Icons/Smileys/Ecstatic";
import dayjs from "dayjs";

interface GraphStatsProps {
  totalCount: number;
  meanReview: string;
  meanNps: string;
  byStars: { five: number; four: number; threeOrLess: number };
  teamMembers: ListTeamMembersQuery;
  invitedUsers: ListTeamMembersQuery["listTeamMembers"];
  refetch: () => void;
  programmesList: ListUserPackagesQuery["listUserPackages"];
}

export default function GraphStats({
  totalCount,
  meanReview,
  meanNps,
  byStars,
  teamMembers,
  invitedUsers,
  refetch,
  programmesList,
}: GraphStatsProps) {
  const isMobile = useIsMobile();

  const [showTeamMembers, setShowTeamMembers] = useState(false);
  const [isPasswordModalOpen, setPasswordModalOpen] = useState(false);
  const [confirmTeamMember, setConfirmTeamMember] = useState<TeamMember | null>(null);

  const pieChartData = [
    { name: "Activated", value: teamMembers.activeCount },
    { name: "Non-Activated", value: teamMembers.count - teamMembers.activeCount },
  ];

  const activeProgrammes = programmesList?.filter(
    (programme) =>
      dayjs(programme.endDate) > dayjs() ||
      !programme.sessions.every((session) => dayjs(session.startsAt) < dayjs()),
  );

  const completedProgrammes = programmesList?.filter(
    (programme) =>
      new Date(programme.endDate) < new Date() ||
      programme.sessions.every((session) => new Date(session.endsAt) < new Date()),
  );

  const barChartData = [
    { name: "Completed", value: completedProgrammes.length },
    { name: "Active", value: activeProgrammes.length },
  ];

  return (
    <>
      {totalCount > 0 ? (
        <div className="w-[95%] computer:w-[45%] flex flex-col m-auto mt-4 pb-4 items-center self-center">
          <div className="w-full flex flex-col bg-white rounded-xl m-auto mt-4 pb-4 items-center self-center">
            <h2 className="mt-6 mb-3 px-4 text-center text-2xl md:text-3xl">
              On average, your team rate our coaches:
            </h2>
            <div className="relative">
              <Star stroke="#F5C900" fill="#F5C900" size={isMobile ? 200 : 250} />
              <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-center">
                <h2 className="text-5xl font-bold m-0">{meanReview}</h2>
                <h2 className="text-xl m-0">/5</h2>
              </div>
            </div>
            <div className="w-3/4 flex flex-col mt-auto gap-y-4">
              <h2 className="text-xl m-0">From {totalCount} reviews:</h2>
              <div className="flex w-full">
                <h2 className="text-xl m-0 pr-3">5/5</h2>
                <div
                  className="rounded-full m-2 pl-3 bg-yellow"
                  style={{ width: `${(byStars.five / totalCount) * 100}%` }}
                />
                <h2 className="text-xl m-0 ml-auto pl-2">{byStars.five}</h2>
              </div>
              <div className="flex w-full">
                <h2 className="text-xl m-0 pr-3">4/5</h2>
                <div
                  className="rounded-full m-2 pl-3 bg-yellow"
                  style={{ width: `${(byStars.four / totalCount) * 100}%` }}
                />
                <h2 className="text-xl m-0 ml-auto pl-2">{byStars.four}</h2>
              </div>
              <div className="flex w-full">
                <h2 className="min-w-16 text-xl m-0 ml-[-16px]">{"≤ "}3/5</h2>
                <div
                  className="rounded-full m-2 pl-3 bg-yellow"
                  style={{ width: `${(byStars.threeOrLess / totalCount) * 100}%` }}
                />
                <h2 className="text-xl m-0 ml-auto pl-2">{byStars.threeOrLess}</h2>
              </div>
            </div>
          </div>
          <div className="w-full flex bg-white rounded-xl m-auto mt-4 p-4 justify-center items-center">
            {!isMobile && (
              <div className="pr-6">
                <Ecstatic size={50} />
              </div>
            )}
            <h2 className="text-2xl m-0 text-center">
              {Math.round(Number(meanNps))} out of 10 in your team would recommend More Happi to a
              friend
            </h2>
            {!isMobile && (
              <div className="pl-6">
                <Ecstatic size={50} />
              </div>
            )}
          </div>
        </div>
      ) : (
        <div className="w-[95%] computer:w-[45%] flex flex-col flex-grow bg-white rounded-xl m-auto computer:ml-4 items-center text-center border-solid border-2 border-bottleGreen computer:mr-6">
          <p className="mt-4 px-4 text-base md:text-xl">
            Your team haven&apos;t left any testimonials yet.
          </p>
          <p className="mt-4 px-4 text-base md:text-xl">
            Once your team has written their first tesimonial, your team&apos;s testimonial stats
            will appear here.
          </p>
          <p className="mt-4 px-4 text-base md:text-xl">
            Reviews and testimonials benefit our coaches, so make sure to encourage your team to
            leave meaningful feedback on their sessions.
          </p>
        </div>
      )}
      <div className="flex flex-col m-auto items-center gap-4 w-[95%] computer:w-[45%] mt-4 pb-4">
        <div className="flex flex-col w-full text-center justify-around m-auto p-4 bg-white rounded-xl">
          <h3 className="text-3xl m-0">Team members onboarded</h3>
          <div className="flex flex-col w-full min-h-[250px] md:flex-row">
            <div className="w-full md:w-1/2 flex flex-col">
              <div className="flex gap-x-4 my-auto justify-center text-center">
                <h3 className="text-lg font-semibold pt-1">Users invited to platform:</h3>
                <p className="text-3xl text-center m-0">{teamMembers.count}</p>
              </div>
              <div className="flex gap-x-4 my-auto justify-center text-center">
                <h3 className="text-lg font-semibold pt-1">Activated users:</h3>
                <p className="text-3xl text-center m-0">{teamMembers.activeCount}</p>
              </div>
              <div className="flex gap-x-4 my-auto justify-center text-center">
                <h3 className="text-lg font-semibold pt-1">Percentage activated:</h3>
                <p className="text-3xl text-center m-0">
                  {Math.round((teamMembers.activeCount / teamMembers.count) * 100)}%
                </p>
              </div>
            </div>
            <div className="w-full md:w-1/2 h-[250px]">
              <OnboardingPieChart data={pieChartData} />
            </div>
          </div>
          <div>
            <Button
              tertiarySolid
              onClick={() => setShowTeamMembers(!showTeamMembers)}
              className="my-auto h-auto"
            >
              {showTeamMembers ? "Hide" : "Show"} non-activated team members
            </Button>
          </div>
        </div>
        <div className="flex flex-col w-full text-center justify-around m-auto p-4 bg-white rounded-xl">
          <h3 className="text-3xl m-0">Coaching programmes</h3>
          <div className="flex flex-col w-full min-h-[250px] md:flex-row">
            <div className="w-full md:w-2/5 flex flex-col">
              <div className="flex gap-x-4 my-auto justify-center text-center">
                <h3 className="text-lg font-semibold pt-1">Total programmes:</h3>
                <p className="text-3xl text-center m-0">{programmesList?.length}</p>
              </div>
              <div className="flex gap-x-4 my-auto justify-center text-center">
                <h3 className="text-lg font-semibold pt-1">Completed programmes:</h3>
                <p className="text-3xl text-center m-0">{completedProgrammes?.length}</p>
              </div>
              <div className="flex gap-x-4 my-auto justify-center text-center">
                <h3 className="text-lg font-semibold pt-1">Active programmes:</h3>
                <p className="text-3xl text-center m-0">{activeProgrammes?.length}</p>
              </div>
            </div>
            <div className="w-full md:w-3/5">
              <ProgrammesBarChart data={barChartData} />
            </div>
          </div>
        </div>
      </div>
      {showTeamMembers && (
        <Modal
          onClose={() => {
            setShowTeamMembers(false);
          }}
          size="lg"
        >
          <div className="flex flex-col w-full justify-center items-center">
            <h3 className="w-3/4 m-0 text-center md:text-xl gap-x-2">
              These team members have been invited to More Happi, but have not yet created their
              account: <br />
            </h3>
            <ul className="w-1/2 flex flex-col pt-4 gap-y-2">
              {invitedUsers?.map((teamMember) => (
                <li className="w-full flex justify-between gap-x-4" key={teamMember.id}>
                  {teamMember.user.firstName} {teamMember.user.lastName}
                  <Button
                    tertiary
                    onClick={() => {
                      setConfirmTeamMember(teamMember as TeamMember);
                      setPasswordModalOpen(true);
                    }}
                  >
                    Re-send invite
                  </Button>
                </li>
              ))}
            </ul>
            <a href="/company/manage-team">
              <Button primary>Manage team members</Button>
            </a>
          </div>
        </Modal>
      )}

      <ConfirmPasswordResetModal
        isOpen={isPasswordModalOpen}
        onClose={setPasswordModalOpen}
        teamMember={confirmTeamMember}
        refetch={refetch}
      />
    </>
  );
}
