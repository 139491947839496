import { Layout } from "antd";
import { HtmlMeta } from "$/components/HtmlMeta";
import { IPageBaseProps } from "$/interfaces";
import { TrainingCard } from "./TrainingCard";

interface IProps extends IPageBaseProps {}

export const Training: React.FC<IProps> = () => {
  return (
    <Layout>
      <HtmlMeta title="More Happi Training" />
      <div className="w-full flex flex-col justify-center items-center py-10 px-4">
        <h1 className="text-3xl font-semibold">More Happi Training</h1>
        <h2 className="text-lg text-center md:text-xl !text-darkGrey xl:max-w-[900px] xl:mx-auto font-normal">
          As a More Happi member, you get free access to monthly online workshops and training.
        </h2>
        <h2 className="text-lg text-center md:text-xl !text-darkGrey xl:max-w-[900px] xl:mx-auto font-normal">
          Register to save your space!
        </h2>
        <h2 className="text-lg text-center md:text-xl !text-darkGrey xl:max-w-[900px] xl:mx-auto">
          School of More Happi - 3 part training series:
        </h2>
        <div className="flex flex-col gap-y-6">
          <TrainingCard
            title="Part 1: How to question like a coach to inspire greater answers"
            date="2025-03-27T13:00:00Z"
            description="Question effectively to get to the root cause of problems and achieve better thinking in those around you."
            imageSrc="/images/Listening.svg"
            href="https://share.hsforms.com/1nLhsYgNPR0yywb2_kfF2lQ57z74"
            altText="Listening"
            sessionType="Coach Training (1hr)"
          />

          <TrainingCard
            title="Part 2: How to listen like a coach to encourage quality thinking"
            date="2025-03-31T12:00:00Z"
            description="Listen like a coach. Demonstrate empathy, practice presence and listen in a way that delivers better outcomes."
            imageSrc="/images/Listening.svg"
            href="https://share.hsforms.com/1nLhsYgNPR0yywb2_kfF2lQ57z74"
            altText="Listening"
            sessionType="Coach Training (1hr)"
          />

          <TrainingCard
            title="Part 3: How to structure a conversation like a coach"
            date="2025-04-03T12:00:00Z"
            description="Structure a conversation, using coaching skills, to get faster and impactful change."
            imageSrc="/images/Listening.svg"
            href="https://share.hsforms.com/1nLhsYgNPR0yywb2_kfF2lQ57z74"
            altText="Listening"
            sessionType="Coach Training (1hr)"
          />
        </div>
      </div>
    </Layout>
  );
};
